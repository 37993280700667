<template>
    <div class="mx-auto container container--fluid">
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <v-app id="inspire" class="">
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="jobs_list" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Job/Internship </v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <span style="width: 20px"></span>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </template>
        </v-app>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            dialog: false,
            jobs_list: null,
            rt_list: null,
            pt_list: null,
            it_list: null,
            industry_list: null,
            errorMsg: {
                id: false
            },
            search: '',
            headers: [{
                    text: "Title",
                    value: "title"
                }, {
                    text: "Company",
                    value: "company"
                }, {
                    text: "Location",
                    value: "location"
                }, {
                    text: "Description",
                    value: "description"
                }, {
                    text: "Package",
                    value: "yearly_package"
                },
                /*{
                     text: "Schedule Date",
                     value: "schedule_date"
                 },*/
                {
                    text: "Company Wesbsite",
                    value: "company_wesbsite"
                }, {
                    text: "Company Address",
                    value: "company_address"
                }, {
                    text: "Company Person Name",
                    value: "company_contact_person_name"
                }, {
                    text: "Company Contact",
                    value: "company_contact"
                }, {
                    text: "Company Email",
                    value: "company_email"
                }, {
                    text: "Job Link",
                    value: "job_link"
                }, {
                    text: "Recruitment Type",
                    value: "recruitmenttype"
                }, {
                    text: "Placement Type",
                    value: "placementtype"
                }, {
                    text: "Internship Duration",
                    value: "internshiptype"
                }, 
                {
                    text: "Industry",
                    value: "industry"
                }, 
                {
                    text: "Post Date",
                    value: "job_post_date"
                }, 
                {
                    text: "Post By Whome",
                    value: "instructor" 
                }
            ],

            editedIndex: -1,
            editedItem: {
                id: 0,
                title: '',
                recruitmenttype: '',
                placementtype: '',
                internshiptype: '',
                industry:'',
                company: '',
                location: '',
                description: '',
                yearly_package: '',
                //schedule_date:'',
                company_wesbsite: '',
                company_address: '',
                company_contact_person_name: '',
                company_contact: '',
                company_email: '',
                job_link: '',
                instructor:'',
                job_post_date:'',
                isactive:true 
            },
            defaultItem: {
                id: 0,
                title: '',
                recruitmenttype: '',
                placementtype: '',
                internshiptype: '',
                industry:'',
                company: '',
                location: '',
                description: '',
                yearly_package: '',
               // schedule_date:'',
                company_wesbsite: '',
                company_address: '',
                company_contact_person_name: '',
                company_contact: '',
                company_email: '',
                job_link: '',
                instructor:'',
                job_post_date:'',
                isactive:true 
            }
        }),

        

       
        mounted() {

            axios
                .post("/LearnerData/addJobInternship")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.jobs_list = res.data.jobs_list
                        this.rt_list = res.data.rt_list
                        this.pt_list = res.data.pt_list
                        this.it_list = res.data.it_list
                        this.industry_list = res.data.industry_list
                        this.load = true;
                    } else {}
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    //window.console.log(error);
                });
        },

    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    
    .v-icon-size {
        font-size: 20px;
    }
    
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    
    .edit-v-icon:hover {
        color: white;
    }
</style>